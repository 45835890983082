@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.filter-bar {
  padding: 0.5rem 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: $neutral-white;
  border-bottom: 1px solid $gray-light;
  z-index: 8;
  width: 100%;

  @media (max-width: $tablet-xl-breakpoint) {
    padding: 0.5rem 1rem;

    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  &__list {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;

    img {
      margin-left: 2rem;
    }

    .pop-up__expand {
      right: -1rem !important;
      width: auto !important;
    }
    .pop-up__li {
      &:last-child {
        border: none !important;
      }
    }
  }

  &__popup {
    position: absolute;
    box-shadow: 0px 4px 8px rgba(39, 40, 51, 0.12);
    border-radius: 2px;
    z-index: 100;
    background-color: $neutral-white;
    min-width: 260px;
    left: 0;
    right: unset;
    top: 2.5rem;
    padding: 1rem 1rem;

    @media (max-width: $tablet-breakpoint) {
      width: 100%;
      min-width: 260px;
      left: 0;
      right: 0;
      margin: 0px auto;
    }
  }

  &__subtitle {
    @include style-subtitle;
    color: $black;
    text-align: left;
    padding-bottom: 0.5rem;
  }

  &__dropdowns {
    width: 25%;
    @media (min-width: $tablet-xl-breakpoint) {
      width: auto;
    }
  }

  &__search {
    width: 50%;
    @media (max-width: $tablet-xl-breakpoint) {
      width: 100%;
      padding-bottom: 1rem;
    }
  }

  &__btns {
    display: flex;
    justify-content: space-between;
    padding-top: 1rem;
    button {
      width: 50%;
    }

    .button-secondary {
      &:hover {
        background-color: $neutral-white;
        color: $black;
      }
    }
  }

  &__dropdowns {
    display: flex;
    justify-content: space-between;
    margin-right: 1rem;
    @media (max-width: $tablet-xl-breakpoint) {
      display: flex;
      justify-content: center;
      padding-bottom: 1rem;
      margin-right: 0;
    }
  }

  &__clear {
    display: flex;
    justify-content: center;
    @include style-subtitle;
    color: $gray;
    cursor: pointer;
    width: 25%;
    @media (max-width: $tablet-xl-breakpoint) {
      width: 100%;
      padding-bottom: 0.5rem;
    }
  }

  &__selected-number {
    border-radius: 100%;
    background-color: $black;
    color: $neutral-white;
    margin-left: 1rem;
    width: 1rem;
    height: 1rem;
    display: flex;
    justify-content: center;
    align-content: center;
    font-size: 10px;
    text-align: center;
    letter-spacing: 0;
  }

  &__dropdown {
    border-radius: 1px;
    padding: 0.5rem 0.8rem;
    position: relative;
    color: $gray-medium;
    @include style-subtitle;
    cursor: pointer;
    display: flex;
    margin-right: 0.25rem;
    border: 1px solid transparent;

    @media (max-width: $tablet-breakpoint) {
      position: unset;
    }

    img {
      padding-left: 0.5rem;
    }
  }

  &__info-icon {
    min-width: 18px;
  }

  &__checkbox {
    margin-bottom: 1.35rem;
    display: block;
    label {
      text-transform: capitalize;
      letter-spacing: 0;
      font-size: 14px;
      color: $gray-dark;
      width: fit-content;
    }
  }

  &__filter-by {
    @include style-body-small;
    display: flex;
    flex-direction: row;
    color: $gray-medium;
    align-items: center;
    cursor: pointer;
    width: 25%;
    @media (max-width: $tablet-breakpoint) {
      width: 100%;
      justify-content: center;
    }
  }

  &__filter {
    width: 16px;
    height: 16px;
    margin-left: 0.5rem;
  }

  &__plus {
    cursor: pointer;
    margin-left: 2rem;
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 25%;

    @media (max-width: $tablet-xl-breakpoint) {
      justify-content: center;
      width: 100%;
    }
  }

  &__dropdown-selected {
    border: 1px solid $black;
  }

  .pop-up__expand {
    right: 2rem;
    width: 192px;
  }

  .pop-up__li {
    display: flex;
    align-items: center;

    &:last-child {
      border-top: 1px solid $gray-lighter;
    }
    &:first-child {
      border-top: 0px;
    }
  }

  img {
    max-width: inherit !important;
  }

  .visible-popup,
  .hidden-popup {
    @media (max-width: $tablet-xl-breakpoint) {
      transition-duration: 0ms;
    }
  }
}
