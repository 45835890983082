@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.livechat {
  position: fixed;
  height: calc(100vh - 4.5rem);
  width: 25rem;
  background: $white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  z-index: 9;
  top: 4.6rem;
  right: 3.1rem;

  @media (max-width: $tablet-breakpoint) {
    right: 0;
    top: 4.95rem;
  }

  &__options {
    position: relative;
  }

  &__inner {
    position: relative;
  }

  &__header {
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }

  &__header-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    span {
      @include style-heading-small;
      font-size: 0.75rem;
    }
  }

  &__placeholder-color::placeholder {
    color: #CBCBCB;
  }

  &__send-btn {
    background-color: $button-gray;
    padding: 0.8rem 1rem;
    margin-top: -0.5rem;
    align-items: center;
  }

  .search {
    margin: 0.8rem 0;
  }

  &__footer {
    width: 100%;
    overflow: hidden;
  }

  &__wrap {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  &__msg__compose {
    min-height: 17.6rem;
    .control--label {
      display: none;
    }
    .control-block {
      margin: 0;
    }
  }

  &__item-msg {
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem;
    cursor: pointer;
  }

  &__body {
    border-top: 1px solid $border-color;
    overflow-y: scroll;
    width: 100%;
  }

  &__avatar {
    border-radius: 100%;
  }

&__flexRow {
  display: flex;
  flex-direction: row;
}

&__mr20 {
  margin-right: 20px;
}
  &__item {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $border-color;
    padding: 1rem 1rem;
    cursor: pointer;
    &__metadata {
      @include style-body-xsmall;
      color: $gray-light-2;
      justify-content: space-between;
      align-items: center;
      justify-content: flex-start;

      &-row {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
      }

      .livechat__avatar {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.8rem;
      }

      .livechat__name {
        margin-right: 0.8rem;
        color: $black-2;
        font-size: 0.75rem;
        text-transform: capitalize;
      }
    }
  }

  &__new-group {
    cursor: pointer;
    color: $gray;
    text-transform: uppercase;
  }

  &__heading {
    @include style-heading-small;
    padding: 0.6rem 1rem;
    font-family: $nunito-bold;
    text-transform: uppercase;
    opacity: 0.4;
    color: $gray;
  }

  &__name {
    @include style-body-xsmall;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__msg {
    @include style-body-small;
    color: $gray-medium;
    padding-top: 0.4rem;

    &__compose {
      .livechat__attach {
        cursor: pointer;
      }
      .livechat__asset {
        &__controls {
          display: flex;
          justify-content: space-between;
          width: 100%;
        }
        &-name {
          @include style-body-xsmall;
          color: $gray;
          padding: 0.075rem;
          width: 12rem;
        }
        &-remove {
          padding: 0.075rem;
          cursor: pointer;
          @include style-body-small;
          color: $gray;
          display: flex;
          align-self: center;
          width: 1rem;
        }
        align-self: center;
      }
      .livechat-attachment__drag-container {
        width: 20%;
      }
      .livechat-attachment__drag-area {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding-right: 2rem;

        &.button-small {
          border-color: $gray-light;
          color: $gray;
        }
      }
    }
  }

  &__header-top-right {
    display: flex;
    align-items: center;
    .style-sample-review-header__wrap {
      margin-right: 1rem;
    }
  }

  &__msg-single-chat {
    padding-left: 1.2rem;
  }

  &__asset {
    margin-top: 0.5rem;
    margin-left: 1.2rem;
    padding: 0.5rem;
    @include style-body-xsmall;
    white-space: nowrap;
    color: $gray;
    display: flex;
    align-items: center;
    border: 1px solid $gray-lighter;
    border-radius: 6px;

    &__metadata {
      width: 100%;
      margin-right: 0.5rem;
      overflow: hidden;
      display: flex;
      align-items: center;

      &-thumbnail {
        margin-right: 0.5rem;

        img,
        span {
          width: 2rem;
          height: 2rem;
          min-width: 2rem;
          min-height: 2rem;
          border-radius: 6px;
        }

        span {
          border: 1px solid $gray-lighter;
          padding-left: 0.3rem;
          display: flex;
          align-items: center;
          overflow: hidden;
          text-transform: uppercase;
        }
      }
      &-name {
        max-width: 15rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 0.5rem;
      }
      &-size {
        margin-left: auto;
      }
    }
    &__download {
      margin-left: auto;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    width: 100%;
  }

  &__dot {
    height: 8px;
    width: 8px;
    background-color: #c53030;
    border-radius: 50%;
    display: inline-block;
  }

  &__avatar {
    font-family: $nunito-bold;
    font-size: 0.8rem;
    background-color: $gray-light-2;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    object-fit: cover;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    position: relative;
  }

  &__avatar-online {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 0.625rem;
    width: 0.625rem;
    background: $live-chat-green;
    border-radius: 100%;
    border: 0.125rem solid $neutral-white;
  }

  &__close {
    cursor: pointer;
  }

  &__group {
    .control--textarea {
      border: 1px;
    }
  }
}

.chat-animation-enter {
  opacity: 0;
}
.chat-animation-enter-active {
  opacity: 1;
  transition: opacity 300ms, transform 300ms;
}

.chat-animation-exit {
  opacity: 1;
}
.chat-animation-exit-active {
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}

.livechat--visible {
  opacity: 1;
  transition: opacity 300ms;
}

.livechat-hidden {
  opacity: 0;
  transition: opacity 150ms;
}
